import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate uninstall`}</strong>{` -- uninstall a root certificate from the system truststore`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate uninstall <crt-file>
[--prefix=<name>] [--all]
[--java] [--firefox] [--no-system]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate uninstall`}</strong>{` uninstalls a root certificate from the system
truststore.`}</p>
    <p>{`Java and Firefox truststores are also supported via the respective flags.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
Certificate to uninstall from the system truststore`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--prefix`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The prefix used to `}<inlineCode parentName="p">{`name`}</inlineCode>{` the CA in the truststore. Defaults to the
certificate common name.`}</p>
    <p><strong parentName="p">{`--java`}</strong>{`
uninstall from the Java key store`}</p>
    <p><strong parentName="p">{`--firefox`}</strong>{`
uninstall from the Firefox NSS security database`}</p>
    <p><strong parentName="p">{`--no-system`}</strong>{`
disables the uninstall from the system truststore`}</p>
    <p><strong parentName="p">{`--all`}</strong>{`
uninstall from the system, Firefox and Java truststores`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Uninstall from only the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate uninstall root-ca.pem
`}</code></pre>
    <p>{`Uninstall a certificate from all the supported truststores:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate uninstall --all root-ca.pem
`}</code></pre>
    <p>{`Uninstall a certificate from Firefox and the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate uninstall --firefox root--ca.pem
`}</code></pre>
    <p>{`Uninstall a certificate from Java and the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate uninstall --java root-ca.pem
`}</code></pre>
    <p>{`Uninstall a certificate from Firefox, Java, but not from the system:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate uninstall --firefox --java --no-system root-ca.pem
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      